import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as indexCss from '../pages/modules/index.module.scss';
import * as textCss from '../pages/modules/text.module.scss';

export const query = graphql`
    query ($slug: String!) {
        contentfulExpoPasse (urlDeLaPage: {eq:$slug}) {
            contenuDeLaPage {
                raw
            }
            titre
            sousTitre
            dateDeLexposition
            dateDeFinDeLexposition
            quelquesOeuvres
            imageMiseEnAvant {
                resize {src}
                file {url}
                title
            }
            autresImages {
                resize {src}
                file {url}
                title
                description
            }
        }
    }
`;

const Expositions = (props) => {
    const data = props.data.contentfulExpoPasse;
    let content = '';
    if (data.contenuDeLaPage !== null) {
        content = JSON.parse(data.contenuDeLaPage.raw);
    }

    return (
        <Layout>
            <div className={textCss.container}>
                <h1>{data.titre}</h1>
                <h2>{data.sousTitre}</h2>
                <a href={data.imageMiseEnAvant.file.url} target="_blank" rel="noreferrer"><img className={textCss.img} src={data.imageMiseEnAvant.resize.src} alt={data.imageMiseEnAvant.title}/></a>
                <p className={textCss.date}>Date de début {data.dateDeLexposition} - Date de fin {data.dateDeFinDeLexposition}</p>
                
                <div>{ documentToReactComponents(content) }</div>

                <h3 style={{marginTop: 50}}>{data.quelquesOeuvres}</h3>
                <div className={indexCss.collection}>
                    {data.autresImages.map(image => {
                        return (
                            <div className={indexCss.imageContainer}>
                                <a href={image.file.url} target="_blank" rel="noreferrer">
                                    <img src={image.resize.src} alt={image.title} className={indexCss.image + ' ' + textCss.expoimg}/>
                                </a>
                                <p className={indexCss.caption}>{image.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
};

export default Expositions;
